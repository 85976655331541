
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { useContext } from 'react'

import { Heading1 } from '@lumoslabs/lumosity-storybook'
import { NextPage } from 'next'
import Head from 'next/head'
import styled from 'styled-components'

import { BrazeContentCard } from '~/components/BrazeContentCard'
import AppLayout from '~/components/layout/AppLayout'
import MobileDevicesInterstitial from '~/components/MobileDevicesInterstitial'
import StreakCard from '~/components/streaks/StreakCard'
import StreakCounter, { StreakCounterContainer } from '~/components/streaks/StreakCounter'
import PageContent from '~/components/ui/PageContent'
import PageDate from '~/components/ui/PageDate'
import WorkoutCardWrapper from '~/components/ui/WorkoutCard'
import { WorkoutModes } from '~/components/workout/WorkoutModes'
import { MarketingContext } from '~/context/MarketingContext'
import useTrackScreenView from '~/events/trackers/useTrackScreenView'
import { useGetUserQuery } from '~/graphql/generated/schema'
import useIsMobileDevice from '~/hooks/useIsMobileDevice'
import useLocalDate from '~/hooks/useLocalDate'
import useMarketingPageView from '~/hooks/useMarketingPageView'
import useScrollToTop from '~/hooks/useScrollToTop'
import useSetL2UserCookie from '~/hooks/useSetL2UserCookie'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { withApollo } from '~/lib/withApollo'

interface HomeProps {
  date: Date
}

const Home: NextPage<HomeProps> = () => {
  // not actually the page slug since this is the root page, but calling it home since that's the tracking name!
  const pageSlug = 'home'
  const t = useTranslationForNamespace(pageSlug)
  useTrackScreenView({ screen_name: 'home' })
  useMarketingPageView(pageSlug)

  useSetL2UserCookie()

  const { data: userQueryData } = useGetUserQuery({ fetchPolicy: 'cache-and-network' })
  const name = userQueryData?.me?.firstName
  const { dateFormatted, greetingKey } = useLocalDate()
  const isMobileDevice = useIsMobileDevice()
  useScrollToTop()

  const { contentCards } = useContext(MarketingContext)

  const headerContentCard = contentCards.find((c) => c.extras?.page === 'home:header')
  const bodyContentCard = contentCards.find((c) => c.extras?.page === 'home:body')

  if (isMobileDevice) {
    return <MobileDevicesInterstitial />
  }

  const greeting = name ? t(`greetingWithName.${greetingKey()}`, { name: name }) : t(`greeting.${greetingKey()}`)

  return (
    <AppLayout>
      <Head>
        <title>{t('title')}</title>
      </Head>
      <PageContent>
        <PageContent.Header>
          {headerContentCard && (
            <BrazeCardContainer>
              <BrazeContentCard card={headerContentCard} />
            </BrazeCardContainer>
          )}
          <StreakCounterContainer>
            <StreakCounter />
          </StreakCounterContainer>
          <PageDate dateString={dateFormatted} />
          <Heading1 as='div'>{greeting}</Heading1>
        </PageContent.Header>
        <PageContent.Main>
          <WorkoutCardWrapper />
          {bodyContentCard && (
            <div className='w-full max-w-[592px] mt-4'>
              <BrazeContentCard card={bodyContentCard} />
            </div>
          )}
          <WorkoutModes />
        </PageContent.Main>
        <PageContent.Sidebar>
          <StreakCard />
        </PageContent.Sidebar>
      </PageContent>
    </AppLayout>
  )
}

const __Page_Next_Translate__ = withApollo({ ssr: true })(Home)

const BrazeCardContainer = styled.div`
  margin-bottom: 24px;
`


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  